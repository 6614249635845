import { AccountService, GetUserTierResponse } from '../../api/coreapi'
import { useApi } from '../useApi'

const isAccountTierResponse = (response: any): response is GetUserTierResponse => {
  return response && typeof response.tier === 'string' && typeof response.has_stripe_customer === 'boolean'
}

export const useFetchUserTier = () => {
  const fetcher = async () => {
    const response = await AccountService.srcHandlersv2AccountGetTier()
    if (isAccountTierResponse(response)) {
      return response
    } else {
      throw new Error('Unexpected response format')
    }
  }

  const { data, loading, error } = useApi<GetUserTierResponse>('userTier', fetcher)

  return {
    userTierInfo: data?.tier ?? null,
    hasStripeCustomer: data?.has_stripe_customer ?? false,
    isLoading: loading,
    error,
  }
}
