import { UrlParams, useUrlState } from '../hooks/useUrlState'
import React, { useMemo } from 'react'
import { usePageTitle } from '../hooks/usePageTitle'
import { Outlet } from 'react-router'
import compact from 'lodash/compact'
import { useAgentKeepAlive } from '../desktop/hooks/useAgentKeepAlive'

const TITLE_PREFIX = 'Diversion'

export const CommonRootElement = () => {
  const params = useUrlState()

  const formattedTitle = useMemo(() => {
    const title = urlParamsToTitle(params)
    return title ? `${TITLE_PREFIX} - ${title}` : title
  }, [params])

  usePageTitle(formattedTitle)

  //Initiating the agent keep alive hook here to apply to all pages
  useAgentKeepAlive()

  return <Outlet />
}

const urlParamsToTitle = (params: UrlParams) => {
  const { repoId, mergeId, conflictId, branchId, commitId, compareCommitId, workspaceId, '*': filePath } = params
  if (conflictId) {
    return `Resolving conflict ${shortId(conflictId)}`
  }
  if (mergeId) {
    return `Resolving merge conflicts ${shortId(mergeId)}`
  }
  if (commitId && compareCommitId) {
    return `Comparing ${shortId(commitId)} to ${shortId(compareCommitId)}`
  }

  const titleParts: string[] = []
  if (repoId || workspaceId) {
    titleParts.push(shortId(workspaceId || repoId || ''))
  }
  if (branchId || commitId) {
    titleParts.push(shortId(commitId || branchId || ''))
  }
  if (filePath) {
    titleParts.push(`'${filePath}'`)
  }

  return compact(titleParts).join(' - ')
}

const shortId = (id: string) => {
  const parts = id.split('.')
  if (parts.length !== 3) {
    return id
  }
  return `${parts[0]}.${parts[1]}.${parts[2]!.split('-')[0]}`
}
