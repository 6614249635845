import useSWR, { BareFetcher, useSWRConfig } from 'swr'
import { log } from '../utils/log'
import { Error as CoreApiError } from '../api/coreapi'
import { notNil } from '../utils/objectUtil'
import { isTokenExpiredError } from '../utils/errorClassify'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { MutatorOptions } from 'swr/_internal'

axios.defaults.headers.common = {
  ...axios.defaults.headers.common,
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
}

export type Refresh = (opts?: MutatorOptions) => Promise<void>

type InProgressApiCall = {
  data?: never
  loading: true
  error?: never
}

type ResolvedApiCall<T> = {
  data: T
  loading: false
  error?: never
}

type FailedApiCall = {
  data?: never
  loading: false
  error: any
}

export type ApiState<T> = (InProgressApiCall | ResolvedApiCall<T> | FailedApiCall) & {
  refresh: Refresh
  isValidating?: boolean
}

export type ApiKey = string | (string | undefined)[]

type RefreshOptions = {
  /**
   *  polling interval in milliseconds
   */
  refreshInterval?: number
  /**
   * polling when the window is invisible (if `refreshInterval` is enabled)
   */
  refreshWhenHidden?: boolean
  /**
   * polling when the browser is offline (determined by `navigator.onLine`)
   */
  refreshWhenOffline?: boolean
}

export function useApi<T>(
  requestKey: ApiKey,
  fetcher: BareFetcher<T | CoreApiError> | null,
  immutable: boolean = false,
  refreshOptions: RefreshOptions = {}
): ApiState<T> {
  const { mutate } = useSWRConfig()
  const refresh: Refresh = async (opts?: MutatorOptions) => {
    await mutate(requestKey, fetcher, opts)
  }

  const { data, error } = useSWR<T | CoreApiError>(
    requestKey,
    fetcher
      ? async () => {
          const response = await fetcher()
          log.info('received api response', `'${requestKey}'`, { response })
          return response
        }
      : null,
    immutable
      ? {
          revalidateIfStale: false,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          ...refreshOptions,
        }
      : !isEmpty(refreshOptions)
        ? refreshOptions
        : undefined
  )
  const hasData = notNil(data)
  if (!error && !hasData) {
    if (!fetcher) {
      return { loading: false, data: undefined, error: undefined, refresh }
    }
    return { loading: true, refresh }
  }
  if (error || !hasData) {
    if (isTokenExpiredError(error)) {
      return { loading: true, refresh }
    }
    return { loading: false, error: { apiError: error, data }, refresh }
  }
  return { data: data as T, loading: false, refresh }
}

type ListedItems<T> = {
  items: T[]
}

export function useItemListApi<T>(
  requestKey: ApiKey,
  fetcher: BareFetcher<ListedItems<T> | CoreApiError> | null,
  immutable: boolean = false
): ApiState<T[]> {
  return useApi(
    requestKey,
    fetcher
      ? async () => {
          const { items } = (await fetcher()) as ListedItems<T>
          return (items || []) as T[]
        }
      : null,
    immutable
  )
}
